<template>
  <div class="row">
    <div class="col col-md-6 col-sm-12">
      <div class="align-middle text-center bg-light pt-5" style="height:100%">No 3D view available</div>
    </div>
    <div class="col col-md-6 col-sm-12 table-responsive">
      <h5>Selected protein:
        <span
              class="selected-pdb"
            >{{protein.name }}</span>
      </h5>
      <table>
        
        <tr>
          <th>Label</th>
          <td>{{protein.label}}</td>
        </tr>
        <tr>
          <th>Genomic position</th>
          <td>{{protein.start}}-{{protein.end}}</td>
        </tr>
        <tr>
          <th>Putative function</th>
          <td>{{protein.funct? protein.funct.replace( "~","'") : NA}}</td>
        </tr>
        <tr>
          <th>C-terminal cleavage site </th>
          <td>{{protein.clevsite? protein.clevsite.replace( "~","'"): NA}}</td>
        </tr>
        <tr>
          <th>Polyprotein start-end</th>
          <td>{{protein.polyprotloc ? protein.polyprotloc:NA}}</td>
        </tr>
        <tr>
          <th>Overlapping structures</th>
          <td>{{toIdString(structures)}}</td>
        </tr>
        <tr>
          <th>Overlapping models</th>
          <td>{{toIdString(models)}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
export default {
  name: "ProteinInfo",
  props: {
    protein: Object,
    structures: Array,
    models: Array
  },
  data: function() {
    return { NA: "n/a" };
  },
  methods: {
    toIdString: function(alist) {
      let ids = alist.map(function(e) {
        return e.label;
      });
      if (ids.length === 0) return this.NA;
      return ids.join(",");
    }
  }
};
</script>

<style scoped>
a.sorting {
  font-weight: bolder;
  color: #007bff;
}
</style>
